<template>
  <b-card
    text-variant="center"
    bg-variant="danger darken-2"
  >
      <b-avatar
      variant="danger"
      size="50"
      class="shadow"
    >
      <feather-icon
        size="28"
        icon="XCircleIcon"
      />
    </b-avatar>
    <!--/ images -->
    <h1 class="mb-1 mt-50 text-white">
      Sorry
    </h1>
    <b-card-text class="m-auto w-75 text-white">
      Your not selected
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
}
</script>
