<template>
  <b-card
    text-variant="center"
    bg-variant="success darken-2"
  >
    <!-- images -->
    <b-img
        :src="require('@/assets/images/illustration/badge.svg')"
        alt="Medal Pic"
    />
    <!--/ images -->
    <h1 class="mb-1 mt-50 text-white">
      Congratulations
    </h1>
    <b-card-text class="m-auto w-75 text-white">
      Check your Wallet please, you have a Elite Emperors NFT
    </b-card-text>
    <b-card-text class="text-white">If you received a congratulations message fill out the below form
      <b-link href=" https://eliteemperors.com/?page_id=813" target="_blank"> https://eliteemperors.com/?page_id=813</b-link>
    </b-card-text>
    <b-card-text class="text-white"> Our team will contact you within 24-48 Hours for the next steps.</b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,BLink
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BLink
  },
}
</script>
