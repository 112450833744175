import axios from "axios";
const url="/api/v1/wht/"
class UserService{
    static getDetail(address){
        let formData = {
            address:address
        } 
        return  axios.post(url+"lstx", formData,
          {   headers: {
            "Content-type": "application/json; charset=UTF-8"
         }
        }
        );
      }
}
export default UserService