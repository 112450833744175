<template>
<div>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        v-bind:style="{'backgroundImage': 'url('+require('@/assets/images/banner/banner.png')+')'}"

      >
        <b-card-body class="card-body">
          <h2 class="text-warning font-weight-bolder">
            Waitlist Elite Emperors
          </h2>
          <!-- form -->
          <b-form class="kb-search-input mt-2">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="address"
                placeholder="Paste your address here..."
                @keydown.enter.prevent="getDetail">
              </b-form-input>
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->
  </div>
  <div>
     <b-row v-if="isBusy">
          <b-col lg="10" offset-lg="2" class="mx-auto">
      <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
          </b-col>
     </b-row>
    <b-row v-else>
      <b-col lg="10" offset-lg="2" class="mx-auto" v-if="details==null">
        <card-home/>
    </b-col>
      <b-col lg="10" offset-lg="2" class="mx-auto" v-if="details && details!=0">
        <address-congrat/>
    </b-col>
    <b-col lg="10" offset-lg="2" class="mx-auto" v-if="details==0">
        <addressnot-found/>
    </b-col>
    </b-row>
  </div>
    <div class="pricing-faq" v-if="details==0">
      <h3 class="text-center">
        How to get Waitlist in Elite Emperors
      </h3>
      <p class="text-center">
        List of steps to obtain Waitlist with us 
      </p>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >
            <app-collapse-item
              v-for="(data,index) in qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
    </div>
</template>

<script>
import {
  BRow, BCol, BCard,BCardHeader, BCardTitle, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend,
  BSpinner
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import UserService from "@/services/UserService"
import AddressCongrat from "@/components/AddressCongratulations.vue"
import AddressnotFound from "@/components/AddressnotFound.vue"
import CardHome from "@/components/CardHome.vue"
export default {
  directives:{Ripple},
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
    BCardHeader,
    AddressCongrat,
    AddressnotFound,
    BSpinner,CardHome
  },
  data() {
    return {
       isBusy:false,
      address: '',
      details:null,
          qandA:[
          //   {question:'Elite',
          //   ans:'Delta Cash es una plataforma de marketing digital que recompensa a todos los usuarios que la utilizan y que cumplen con las tareas que muestra la plataforma.' },
          //   {question:'¿Dónde puedo obtener más información sobre Delta Cash?',
          //   ans:'Una de las formas de obtener la información es dando click sobre el administrador del grupo de WhatsApp, en su perfil de WhatsApp business hay un catálogo con los recursos de Delta Cash. La otra forma es mediante nuestro servidor de discord en el canal de “Tutoriales-delta cash” <a href="url">https://discord.com/channels/1039179280598454292/1039222565429854278</a>. Esas son las 2 fuentes donde pueden encontrar toda la información sobre Delta Cash.' },
          //   {question:'¿Tengo un problema con Delta Cash que debo hacer?',
          //   ans:'Para dudas o consultas sobre delta cash, pueden escribir por nuestro de WhatsApp o preferiblemente al grupo de discord al canal <a href="url">https://discord.com/channels/1039179280598454292/1039257675856359444</a> “chat de apoyo” y ahí pueden hacer sus preguntas o dudas.  En discord pueden escribir @jonsnow y luego mensaje, de esta forma estarán haciendo mención al administrador para que los apoye. ' },
          // {question:'¿Como accedo al grupo de WhatsApp o Discord de Delta Cash?',
          // ans:' Solamente deben hacer click en los siguientes enlaces.<br/> WhatsApp: <a href="url">https://wa.me/87518741</a><br/> Discord:<a href="url">https://discord.gg/zzYRDgY3</a>' },
       ]
    }
  },
  methods:{
    getDetail:async function(){
      this.details=null
      this.isBusy=true
      const det=(await UserService.getDetail(this.address)).data
      this.details=det 
      this.isBusy=false
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
