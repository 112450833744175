<template>
  <b-card
    text-variant="center">
    <b-avatar
      size="50"
      class="shadow"
    >
      <feather-icon
        size="50"
        icon="ArrowUpCircleIcon"
      />
    </b-avatar>
    <b-card-text class="mb-0 mt-50 text-white">
     Paste your address in the search bar to obtain more information
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
}
</script>
